// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/helper.ts"
);
import.meta.hot.lastModified = "1730627374548.3315";
}
// REMIX HMR END

import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { unparse } from "papaparse";
import { v4 as uuidv4 } from "uuid";
import { deleteFile, uploadFile } from "./helper.server";
import { handleResponse } from "~/utils";
import * as yup from "yup";
import { canConsume } from "~/models/subscription.server";

export const checkNullInfo = (info: string | number | null | undefined) => {
  if (info || typeof info == "number") {
    return info;
  }
  return "---";
};

export function checkDecimal(inputValue: any) {
  const decimal = /^[-+]?[0-9]+\.[0-9]+$/;
  if (inputValue && inputValue.length && inputValue.match(decimal)) {
    return parseFloat(inputValue).toFixed(2);
  }
  if (inputValue) {
    return inputValue;
  }
  return 0;
}

export function regEmailTemplate(name: string): string {
  return `
    <div style="font-family: Arial, sans-serif; line-height: 1.6;">
      <h2>Welcome to AllinOne</h2>
      <p>Assalamu Alaikum ${name},</p>
      <p>We're excited to have you on board.</p>
      <p>If you have any questions, feel free to reach out to us.</p>
      <p>Best Regards,</p>
      <p>AllinOne Team</p>
    </div>
  `;
}

export function getDateString(dateStr: string): string {
  const date: Date = new Date(dateStr);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().slice(-2)}`;
}

export const showConfirmationAlert = async (
  title: string,
  text: string,
  confirmButtonText: string,
  cancelButtonText: string,
) => {
  const result = await Swal.fire({
    title,
    text,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3085d6",
    confirmButtonText,
    cancelButtonText,
  });

  return result.isConfirmed;
};

export function generateRandomSixDigitNumber(): number {
  return Math.floor(100000 + Math.random() * 900000);
}

export function generateRandomString(length: number) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getMediaPath(fileName: string) {
  return `/assets/media/${fileName}`;
}

export function getProfilePhotoPath(fileName: string | undefined) {
  return `/uploads/${fileName}`;
}

interface ProfileCompletion {
  first_name: string;
  last_name: string;
  phone?: string;
  gender?: string;
  age?: number;
  birth?: Date;
  address?: string;
  profile_image: string;
}
export function calculateProfileCompletion(
  profile: ProfileCompletion | undefined,
): number {
  if (!profile) {
    return 100;
  }
  const totalFields = Object.keys(profile).length;
  let completedFields = 0;

  for (const key in profile) {
    if (
      profile[key as keyof ProfileCompletion] !== undefined &&
      profile[key as keyof ProfileCompletion] !== null
    ) {
      completedFields++;
    }
  }

  return (completedFields / totalFields) * 100;
}

export function createPublicUrl(url: string) {
  if (url) {
    if (url.includes("uploads\\")) {
      let splitUrl = url.split("uploads\\");
      return `/uploads/${splitUrl[1]}`;
    } else {
      return `/uploads/${url}`;
    }
  }

  return undefined;
}

export function createUrl(url: string) {
  if (url.includes("uploads\\")) {
    let splitUrl = url.split("uploads\\");
    return splitUrl[1];
  }
  return url;
}

export function generateUniqueFilename(
  baseFilename: string,
  extension: string,
): string {
  const uniqueId = uuidv4();
  return `${baseFilename}_${uniqueId}.${extension}`;
}

export async function exportToXLSX(
  data: any[],
  columnMapping: Record<string, string>,
  baseFilename: string,
  columnWidths: Record<string, number> = {},
): Promise<void> {
  return new Promise((resolve) => {
    const filteredData = data.map((item) =>
      Object.keys(columnMapping).reduce((acc, key) => {
        const keys = key.split("."); // Split column by dot notation
        acc[columnMapping[key]] = keys.reduce(
          (obj, k) => (obj && obj[k] !== "undefined" ? obj[k] : null),
          item,
        );
        return acc;
      }, {} as any),
    );

    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    // Apply column widths
    const colWidths = Object.keys(columnMapping).map((key) => ({
      wpx: columnWidths[key] || 100, // Default width if not specified
    }));
    worksheet["!cols"] = colWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const filename = generateUniqueFilename(baseFilename, "xlsx");
    XLSX.writeFile(workbook, filename);
    resolve();
  });
}

export async function exportToCSV(
  data: any[],
  columnMapping: Record<string, string>,
  baseFilename: string,
): Promise<void> {
  return new Promise((resolve) => {
    const filteredData = data.map((item) =>
      Object.keys(columnMapping).reduce((acc, key) => {
        const keys = key.split("."); // Split column by dot notation
        acc[columnMapping[key]] = keys.reduce(
          (obj, k) => (obj && obj[k] !== "undefined" ? obj[k] : null),
          item,
        );
        return acc;
      }, {} as any),
    );

    const csv = unparse(filteredData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const filename = generateUniqueFilename(baseFilename, "csv");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    resolve();
  });
}

export async function updateFile(
  file: File | null,
  prevFile: string | undefined,
) {
  if (file) {
    if (file.size > 0) {
      if (prevFile) {
        await deleteFile(createUrl(prevFile));
      }
      return await uploadFile(file, "images");
    } else {
      if (prevFile) {
        return prevFile;
      }
    }
  } else {
    return "";
  }
}

export function handleValidationErrors(error: unknown) {
  if (error instanceof yup.ValidationError) {
    const errorFields: Record<string, string> = {};
    error.inner.forEach((err: any) => {
      errorFields[err.path] = err.message;
    });

    return handleResponse({
      success: false,
      msg: "Validation error",
      status: 422,
      errors: errorFields,
    });
  }

  // Return a generic validation error if it's not a Yup error
  return handleResponse({
    success: false,
    msg: "Validation error",
    status: 422,
    errors: error,
  });
}

export async function checkCanConsume(
  featureName: string,
  userId: string,
  planId: string,
) {
  const userCanConsume = await canConsume(featureName, userId, planId);

  if (!userCanConsume) {
    return handleResponse({
      success: false,
      msg: "Cannot consume this feature",
      status: 200,
      data: {},
      redirectTo: "/subscription/payment?canConsume=false",
    });
  }

  return null; // Return null if the user can consume, meaning the flow can continue
}

export const handleNavigate = (
  navigate: Function,
  page: number,
  limit: number,
  search?: string,
  extraParams: Record<string, string> = {},
) => {
  const searchParams = new URLSearchParams({
    limit: limit.toString(),
    page: page.toString(),
  });
  if (search) {
    searchParams.set("search", search);
  }

  Object.keys(extraParams).forEach((key) => {
    if (extraParams[key]) {
      searchParams.set(key, extraParams[key]);
    }
  });
  navigate({
    pathname: location.pathname,
    search: searchParams.toString(),
  });
};

export function getCurrencySymbol(currency: string | undefined): string {
  switch (currency?.toUpperCase()) {
    case "GBP":
      return "£"; // Pound symbol
    case "USD":
      return "$"; // Dollar symbol
    default:
      return ""; // Default to Dollar symbol if not GBP or USD
  }
}

export function formatCuid(cuid: string): string {
  const part1 = cuid.slice(0, 3);
  const part2 = cuid.slice(3, 6);

  return `${part1}_${part2}`;
}

const planFeatures: Record<string, string[] | Record<string, string[]>> = {
  free: ["10 product", "10 deal", "10 lead"],
  starter: {
    week: ["1 deal creation", "1 lead creation"],
    month: [
      "Unlimited deal creation",
      "Unlimited lead creation",
      "Unlimited product creation",
    ],
    "3month": ["5 deal creation", "5 lead creation"],
    "6month": [
      "10 deal creation",
      "10 lead creation",
      "Unlimited notification",
      "Unlimited Chat",
    ],
    year: [
      "Unlimited deal creation",
      "Unlimited lead creation",
      "Unlimited product creation",
    ],
  },
  pro: {
    week: ["5 deal creation", "5 lead creation"],
    "3month": ["15 deal creation", "15 lead creation"],
    "6month": ["30 deal creation", "30 lead creation"],
    year: ["60 deal creation", "60 lead creation"],
  },
  enterprise: {
    week: ["10 deal creation", "10 lead creation"],
    "3month": ["30 deal creation", "30 lead creation"],
    "6month": ["60 deal creation", "60 lead creation"],
    year: ["120 deal creation", "120 lead creation"],
  },
};

export function getPlanFeatures(
  planName: string,
  periodicity: string = "",
): string[] {
  const plan = planFeatures[planName.toLowerCase()];

  if (Array.isArray(plan)) {
    return plan;
  }

  return plan?.[periodicity.toLowerCase()] || [];
}

export function formatStringForFeature(input: string) {
  return input
    .split("-") // Split the string by hyphen
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(" "); // Join with a space
}
